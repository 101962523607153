import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import Eye from '../../assets/images/eye.svg';
import CrossedEye from '../../assets/images/crossedEye.svg';
import styles from './CheckboxField.css';
export const CheckboxField = memo(function CheckboxField(props) {
    const { id, label, onChange, value, checked = false, className, checkBoxClassName, disabled = false, withEye, } = props;
    const labelClassName = classnames(styles.label, {
        [styles.disabled]: disabled,
    });
    const containerClassName = classnames(styles.container, className);
    const checkBoxLabelClassName = classnames(styles.checkMark, checkBoxClassName);
    const checkBoxInputClassName = classnames(styles.checkboxField, checkBoxClassName);
    const handleCheckboxChange = useCallback(({ target }) => onChange(id, target.checked, label, value), [id, label, value, onChange]);
    const icon = useMemo(() => (checked ? _jsx(Eye, {}, void 0) : _jsx(CrossedEye, {}, void 0)), [checked]);
    return (_jsxs("div", Object.assign({ className: containerClassName }, { children: [_jsx("input", { type: "checkbox", id: id, onChange: handleCheckboxChange, value: value, checked: checked, className: checkBoxInputClassName, disabled: disabled, "data-testid": "checkbox" }, void 0), !withEye && _jsx("span", { className: checkBoxLabelClassName }, void 0), _jsxs("label", Object.assign({ htmlFor: id, className: labelClassName }, { children: [withEye && icon, label] }), void 0)] }), void 0));
});
