import { DEFAULT_AXIS_LABEL_FONT_FAMILY, DEFAULT_AXIS_LABEL_FONT_SIZE, DEFAULT_Y_AXIS_LABEL_HEIGHT, } from '../../consts';
import { getTextWidth } from '../../../../utils';
/**
 * Calculates geometry rect for label on Y axis
 *
 * If no text provided `null` rect will be returned
 *
 * @param YAxisZoneWidth - Width of the Y axis zone
 * @param [axisTitle] - Label text
 * @returns Geometry rect
 */
export const calculateYAxisLabelRect = (YAxisZoneWidth, axisTitle) => {
    if (axisTitle === undefined) {
        return null;
    }
    const height = DEFAULT_Y_AXIS_LABEL_HEIGHT || DEFAULT_AXIS_LABEL_FONT_SIZE;
    const width = getTextWidth(axisTitle, DEFAULT_AXIS_LABEL_FONT_FAMILY);
    const x = YAxisZoneWidth;
    const y = DEFAULT_AXIS_LABEL_FONT_SIZE;
    return {
        x,
        y,
        height,
        width,
    };
};
