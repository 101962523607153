import { DEFAULT_AXIS_LABEL_FONT_FAMILY, DEFAULT_AXIS_LABEL_FONT_SIZE, DEFAULT_X_AXIS_LABEL_HEIGHT, } from '../../consts';
import { getTextWidth } from '../../../../utils';
/**
 * Calculates geometry rect for label on X axis
 *
 * If no text provided `null` rect will be returned
 *
 * @param YAxisZoneWidth - Width of the X axis zone
 * @param [axisTitle] - Label text
 * @returns Geometry rect
 */
export const calculateXAxisLabelRect = (XAxisZoneRect, axisTitle) => {
    if (axisTitle === undefined) {
        return null;
    }
    const height = DEFAULT_X_AXIS_LABEL_HEIGHT || DEFAULT_AXIS_LABEL_FONT_SIZE;
    const width = getTextWidth(axisTitle, DEFAULT_AXIS_LABEL_FONT_FAMILY);
    // Since this label has `textAnchor = "end"`
    const x = XAxisZoneRect.x + XAxisZoneRect.width;
    const y = XAxisZoneRect.y + height;
    return {
        x,
        y,
        height,
        width,
    };
};
