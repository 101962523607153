import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, memo } from 'react';
import { getTextWidth } from '../../../../utils';
import { AXIS_BORDER_WIDTH, SCALE_LABEL_FONT_FAMILY, SCALE_LABEL_FONT_SIZE, Y_SCALE_MIN_TICK_DISTANCE, X_SCALE_MIN_TICK_DISTANCE, } from '../../consts';
import { transformScaleValueToString } from '../../utils';
import { getScaleCoordinates } from './utils';
import inlineStyles from './LinePlotAxis.styles';
export const LinePlotAxis = memo(function LinePlotAxis({ rect, labelRect, scale, scaleRect, label, unitLabel, isVertical = false, numberOfSubTicks = 0, }) {
    const { ticks, subTicks } = getScaleCoordinates(rect, scaleRect, scale, numberOfSubTicks, isVertical);
    let secondLastTickValue = '';
    let lastTickValue = '';
    const tickElements = ticks.map(({ line, text }, index) => {
        const isSecondLastElement = index === ticks.length - 2;
        const isLastElement = index === ticks.length - 1;
        let textAnchor = 'start';
        let scaleValue = transformScaleValueToString(scale[index]);
        if (!isVertical) {
            textAnchor = isLastElement ? 'end' : 'middle';
            scaleValue = isLastElement ? `${scaleValue}${unitLabel}` : scaleValue;
        }
        if (isSecondLastElement) {
            secondLastTickValue = scaleValue;
        }
        if (isLastElement) {
            lastTickValue = scaleValue;
        }
        return (_jsxs(Fragment, { children: [_jsx("line", Object.assign({}, line, { style: inlineStyles.tickLine }), void 0), _jsx("text", Object.assign({}, text, { textAnchor: textAnchor, style: inlineStyles.tickLabel }, { children: scaleValue }), void 0)] }, `${line.x1}${line.y1}`));
    });
    let tickRemoved = false;
    if (secondLastTickValue && lastTickValue) {
        const minDistance = isVertical ? Y_SCALE_MIN_TICK_DISTANCE : X_SCALE_MIN_TICK_DISTANCE;
        const lastTickWidth = isVertical
            ? SCALE_LABEL_FONT_SIZE
            : getTextWidth(lastTickValue, SCALE_LABEL_FONT_FAMILY) * 2;
        const secondLastTickWidth = isVertical
            ? SCALE_LABEL_FONT_SIZE
            : getTextWidth(secondLastTickValue, SCALE_LABEL_FONT_FAMILY);
        const currentWidth = (lastTickWidth + secondLastTickWidth) / 2 + minDistance;
        const allowedWidth = isVertical
            ? ticks[ticks.length - 2].text.y - ticks[ticks.length - 1].text.y
            : ticks[ticks.length - 1].text.x - ticks[ticks.length - 2].text.x;
        if (currentWidth > allowedWidth) {
            tickElements.splice(tickElements.length - 2, 1);
            tickRemoved = true;
        }
    }
    const subTickElements = subTicks.map(({ line }, index) => {
        if (index > subTicks.length - numberOfSubTicks - 1 && tickRemoved) {
            return null;
        }
        return (_jsx("line", Object.assign({}, line, { style: inlineStyles.tickLine }), `${line.x1}${line.y1}`));
    });
    return (_jsxs(_Fragment, { children: [isVertical ? (_jsx("line", { x1: rect.x + rect.width - AXIS_BORDER_WIDTH / 2, x2: rect.x + rect.width - AXIS_BORDER_WIDTH / 2, y1: rect.y, y2: rect.y + rect.height, style: inlineStyles.axisLine }, void 0)) : (_jsx("line", { x1: rect.x, x2: rect.x + rect.width, y1: rect.y + AXIS_BORDER_WIDTH / 2, y2: rect.y + AXIS_BORDER_WIDTH / 2, style: inlineStyles.axisLine }, void 0)), subTickElements, tickElements, labelRect && (_jsx("text", Object.assign({ x: labelRect.x, y: labelRect.y, textAnchor: "end", style: inlineStyles.axisLabel }, { children: label }), void 0))] }, void 0));
});
