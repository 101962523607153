import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useMemo, useRef, } from 'react';
import classNames from 'classnames';
import { ZoomControls, PlotSelectRegion, SyntheticScrollX, SyntheticScrollY, } from '../../common';
import useZoom from '../../common/hooks/useZoom';
import useZoomRectangle from '../../common/hooks/useZoomRectangle';
import { getAxis } from '../../common/utils/getAxis';
import { DEFAULT_DATA_ZONE_TOP_PADDING, DEFAULT_PLOT_HEIGHT, DEFAULT_PLOT_WIDTH, DEFAULT_X_AXIS_HEIGHT, DEFAULT_Y_AXIS_WIDTH, OUTER_CONTAINER_RIGHT_PADDING, SYNTHETIC_SCROLL_MARGIN, Y_SCROLLBAR_BOTTOM_PADDING, Y_SCROLLBAR_TOP_PADDING, } from '../consts';
import { SCROLLABLE_PLOT_LEFT_PADDING, SCROLLABLE_PLOT_BOTTOM_PADDING, SCROLLBAR_MARGIN, } from './consts';
import { useZoneConstructor } from './hooks';
import FESPlotData from './components/FESPlotData/FESPlotData';
import { getAxisScaleLineStyles, getScales } from './utils';
import PlotAxisScrollableX from '../components/PlotAxisScrollable/PlotAxisScrollableX';
import PlotAxisScrollableY from '../components/PlotAxisScrollable/PlotAxisScrollableY';
import PlotAxisScrollableBorder from '../components/PlotAxisScrollable/PlotAxisScrollableBorder';
import ScaleLabelsY from '../components/ScaleLabelsY/ScaleLabelsY';
import ScaleLabelsX from '../components/ScaleLabelsX/ScaleLabelsX';
import { EPlot } from '../../common/types';
import styles from './FESScrollablePlot.css';
const FESScrollablePlot = ({ data, height = DEFAULT_PLOT_HEIGHT, width = DEFAULT_PLOT_WIDTH, plotSettings, axisTitles, tooltipContent, forExport = false, allowZoom = true, axis: axisProp, useDataLimits = false, zoomConfig, zoomControlClassName, dashedLineLabel, withZoomReset = false, labelYFormatter, description, }) => {
    var _a;
    const labelsRef = useRef(null);
    const axis = useMemo(() => (useDataLimits ? getAxis(axisProp) : axisProp), [axisProp, useDataLimits]);
    const containerRef = useRef(null);
    const innerContainerRef = useRef(null);
    const yAxisRef = useRef(null);
    const { scale, handleZoomChange, axisHeight, axisWidth, zoomMinValue, zoomMaxValue, zoomStep, scaleXStepWidth, zoomIndex, scaleYStepWidth, } = useZoom({
        axis,
        height,
        width,
        zoomConfig,
        plotBottomPadding: SCROLLABLE_PLOT_BOTTOM_PADDING,
        customGetScales: getScales,
    });
    useEffect(() => {
        if (innerContainerRef.current && labelsRef.current) {
            labelsRef.current.style.left = `-${innerContainerRef.current.scrollLeft}`;
        }
    }, [(_a = innerContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft]);
    const { handleMouseDown, handleMouseUp, handleMouseMove, selectRegionStart, selectRegionWidth, selectRegionTop, selectRegionHeight, } = useZoomRectangle({
        scaleXStepWidth,
        scale,
        scaleYStepWidth,
        containerRef,
        innerContainerRef,
        axisWidth,
        zoomMinValue,
        zoomMaxValue,
        handleZoomChange,
        zoomIndex,
        topPadding: DEFAULT_DATA_ZONE_TOP_PADDING,
    });
    const { XAxisZone, YAxisZone, dataZone, } = useZoneConstructor(axisHeight, axisWidth, data, plotSettings, axisTitles, labelYFormatter);
    const calculateAxisScaleLineStyles = useCallback((label, orientation) => getAxisScaleLineStyles(label, orientation, dashedLineLabel), [dashedLineLabel]);
    if (!innerContainerRef) {
        return null;
    }
    const square = {
        top: YAxisZone.rect.y,
        left: XAxisZone.rect.x,
        height: YAxisZone.rect.height,
        width: XAxisZone.rect.width,
    };
    const outerContainerClassName = classNames(styles.outerContainer, {
        [styles.hideScrollBar]: zoomIndex === 1,
    });
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.wrapper, style: { width: `${width + OUTER_CONTAINER_RIGHT_PADDING + SCROLLBAR_MARGIN}px` } }, { children: [_jsx(SyntheticScrollY, { containerHeight: height, contentHeight: axisHeight, contentRef: containerRef, yAxisRef: yAxisRef, marginTop: Y_SCROLLBAR_TOP_PADDING, marginBottom: Y_SCROLLBAR_BOTTOM_PADDING, customStyled: true }, void 0), _jsx("div", Object.assign({ className: classNames(styles.axisLabel, styles.axisLabelX), style: { top: `${height - DEFAULT_X_AXIS_HEIGHT}px` } }, { children: (axisTitles === null || axisTitles === void 0 ? void 0 : axisTitles.XAxisTitle) || '' }), void 0), _jsx("div", Object.assign({ className: classNames(styles.axisLabel, styles.axisLabelY), style: {
                                    paddingLeft: SCROLLABLE_PLOT_LEFT_PADDING,
                                } }, { children: (axisTitles === null || axisTitles === void 0 ? void 0 : axisTitles.YAxisTitle) || '' }), void 0), _jsxs("div", Object.assign({ className: styles.scrollableLayout }, { children: [_jsx(PlotAxisScrollableBorder, { size: { width, height }, square: square, zoomIndex: zoomIndex }, void 0), _jsx(ScaleLabelsX, { labelsRef: labelsRef, axisZone: XAxisZone, size: { width: axisWidth, height }, inlineStylesGetter: calculateAxisScaleLineStyles }, void 0), _jsx("div", Object.assign({ className: outerContainerClassName, style: {
                                            width,
                                            height,
                                        }, ref: containerRef }, { children: _jsxs("div", Object.assign({ className: styles.innerContainer, ref: innerContainerRef }, { children: [_jsx(PlotAxisScrollableY, { yAxisRef: yAxisRef, size: { width: axisWidth, height: axisHeight }, axisZone: YAxisZone, inlineStylesGetter: calculateAxisScaleLineStyles }, void 0), _jsx(ScaleLabelsY, { axisZone: YAxisZone, size: { width: axisWidth, height: axisHeight }, inlineStylesGetter: calculateAxisScaleLineStyles }, void 0), _jsx(PlotAxisScrollableX, { yAxisRef: yAxisRef, innerContainerRef: innerContainerRef, containerRef: containerRef, containerHeight: height, size: { width: axisWidth, height: axisHeight }, axisZone: XAxisZone, labelsRef: labelsRef }, void 0), _jsx("svg", Object.assign({ height: axisHeight, width: axisWidth, viewBox: `0 0 ${axisWidth} ${axisHeight}`, xmlns: "http://www.w3.org/2000/svg", onMouseDown: handleMouseDown, className: styles.plotDataContainer, "data-testid": "fesPlotDataPoints" }, { children: _jsx(FESPlotData, { dataZone: dataZone, tooltipContent: tooltipContent, forExport: forExport }, void 0) }), void 0)] }), void 0) }), void 0), allowZoom && (_jsxs(_Fragment, { children: [_jsx(PlotSelectRegion, { plot: EPlot.FES, onMouseUp: handleMouseUp, onMouseMove: handleMouseMove, isVisible: !!selectRegionStart, left: selectRegionStart, top: selectRegionTop - window.scrollY, height: selectRegionHeight, width: selectRegionWidth }, void 0), _jsx(SyntheticScrollX, { containerWidth: width, contentWidth: axisWidth, contentRef: innerContainerRef, yAxisRef: yAxisRef, additionalRef: labelsRef, marginLeft: DEFAULT_Y_AXIS_WIDTH, marginRight: SYNTHETIC_SCROLL_MARGIN, customStyled: true }, void 0)] }, void 0))] }), void 0)] }), void 0), description] }), void 0), allowZoom && (_jsx(ZoomControls, { value: zoomIndex, step: zoomStep, minValue: 1, maxValue: zoomMaxValue, onZoomChange: handleZoomChange, className: zoomControlClassName, withZoomReset: withZoomReset }, void 0))] }, void 0));
};
export default memo(FESScrollablePlot);
