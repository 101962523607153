export const isRows = (data) => {
    const keys = Object.keys(data);
    if (keys.length !== 1) {
        return false;
    }
    const property = keys[0];
    if (!(data[property] instanceof Object)) {
        return false;
    }
    // eslint-disable-next-line no-prototype-builtins
    return data[property].hasOwnProperty('rows');
};
export const getCountRows = (data) => {
    const key = Object.keys(data)[0];
    const { rows } = data[key];
    return rows.reduce((acc, item) => {
        if (isRows(item)) {
            return acc + getCountRows(item);
        }
        return acc + 1;
    }, 0);
};
/**
 * Transforms table data from `TInputTableData` to `TOutputTableData` type
 *
 * @param data - table data is type `TInputTableData`
 * @returns - table data is type `TOutputTableData`
 */
export const transformTableData = (data, parents = [], allParent = true) => data.reduce((acc, item, index) => {
    if (isRows(item)) {
        const key = Object.keys(item)[0];
        const { rows } = item[key];
        return acc.concat(transformTableData(rows, [...parents, [item, getCountRows(item)]], !index));
    }
    if (parents.length) {
        const result = parents.reduce((acum, [elem, count], i) => {
            const entries = Object.entries(elem);
            const { value, className } = entries[0][1];
            if (i === parents.length - 1) {
                return Object.assign(Object.assign({}, acum), { [entries[0][0]]: !index
                        ? {
                            value,
                            className,
                            rowspan: count,
                        }
                        : { display: false } });
            }
            return Object.assign(Object.assign({}, acum), { [entries[0][0]]: allParent && !index
                    ? {
                        value,
                        className,
                        rowspan: count,
                    }
                    : { display: false } });
        }, {});
        return acc.concat(Object.assign(Object.assign({}, result), item));
    }
    return acc.concat(item);
}, []);
