/**
 * Returns `true` if data type is TCellItem
 *
 * @param data - cell data from TableData
 * @returns true if data type is TCellItem
 */
export const isConfigObject = (data) => {
    if (typeof data !== 'object') {
        return false;
    }
    const keys = Object.keys(data);
    if (keys.includes('display')
        || (keys.includes('value') && keys.includes('rowspan'))
        || (keys.includes('value') && keys.includes('className'))) {
        return true;
    }
    return false;
};
