/**
 * Returns label for items in singular when the items quantity equals 1
 * and plural when items quantity is more than 1
 *
 * @param label label for items
 * @param quantity quantity of items
 * @returns modified label
 */
export const singlePluralConvert = (label, quantity) => {
    if (!label) {
        return label;
    }
    const isSingle = quantity === 1;
    if (label.slice(-1) === 's' && isSingle) {
        return label.slice(0, -1);
    }
    if (label.slice(-1) !== 's' && !isSingle) {
        return `${label}s`;
    }
    return label;
};
