import inlineStyles from '../../LollipopPlotDataPoint.style';
import { EImpactLevel } from '../../../../../../types';
import { LollipopVariantType } from '../../../../types';
export const getInlineStyles = (variantType, useInlineStyles, impactLevel) => {
    if (!useInlineStyles) {
        return undefined;
    }
    if (variantType === LollipopVariantType.Inactive) {
        return inlineStyles.circleInactive;
    }
    if (impactLevel === EImpactLevel.High) {
        return inlineStyles.circleHighImpact;
    }
    if (impactLevel === EImpactLevel.Moderate) {
        return inlineStyles.circleModerateImpact;
    }
    if (impactLevel === EImpactLevel.Low) {
        return inlineStyles.circleLowImpact;
    }
    return undefined;
};
