/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useCallback, useRef, useEffect, } from 'react';
import { insideArea, getElementPosition, limitByRange, } from '../../../utils';
import { calculatePositionWithRatio } from '../../utils/calculatePositionWithRatio/calculatePositionWithRatio';
import { EKeyCode } from '../../../consts';
import { SELECTION_PADDING } from './consts';
/**
 * Hooks provides API for selecting region on plot
 *
 * @param [innerContainerRef] - Container which maintains scroll position
 * @param [onSelect] - Callback to be called after selecting an area
 * @param [allowZooming = true] - Allow using zoom feature and select are or not
 * @param [offset = [0, 0, 0, 0]] - Inner paddings for container's rectangle where select is allowed
 * @param [minAllowedRegionToSelect = [10, 10]] - Minimum allowed size of rectangle to select
 * @param [ratio] - side ratio of the plot
 * @returns Select region API:
 * - Callbacks for handling user select for container element
 * - Position of the selected area
 * - Should region selector become visible or not
 */
export function useSelectRegion({ innerContainerRef, onSelect, allowZooming = true, offset = [0, 0, 0, 0], minAllowedRegionToSelect = [SELECTION_PADDING, SELECTION_PADDING], ratio, }) {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState(null);
    const animationFrameRef = useRef(null);
    const containerOffsetRef = useRef(null);
    const startPointRef = useRef([0, 0]);
    const getAllowedRegion = useCallback((target) => {
        const targetElement = target;
        const allowedRegion = [
            offset[0] - SELECTION_PADDING,
            targetElement.clientWidth - offset[1] + SELECTION_PADDING,
            targetElement.clientHeight - offset[0] + offset[2] + SELECTION_PADDING,
            offset[3] - SELECTION_PADDING,
        ];
        return allowedRegion;
    }, [offset]);
    const resizeRegion = useCallback((positionX, positionY, allowedRegion) => {
        setPosition((currentPosition) => {
            if (!currentPosition) {
                return null;
            }
            const updatedPosition = Object.assign({}, currentPosition);
            const [startPointX, startPointY] = startPointRef.current;
            let updatedPositionX = positionX;
            let updatedPositionY = positionY;
            if (allowedRegion) {
                updatedPositionX = limitByRange(updatedPositionX, allowedRegion[3], allowedRegion[1]);
                updatedPositionY = limitByRange(updatedPositionY, allowedRegion[0], allowedRegion[2]);
            }
            updatedPosition.left = Math.min(startPointX, updatedPositionX);
            updatedPosition.width = Math.abs(updatedPositionX - startPointX);
            updatedPosition.top = Math.min(startPointY, updatedPositionY);
            updatedPosition.height = Math.abs(updatedPositionY - startPointY);
            return updatedPosition;
        });
    }, []);
    const resetRegion = useCallback(() => {
        setIsVisible(false);
        if (animationFrameRef.current !== null) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
        }
        setPosition(null);
    }, []);
    const handleStartSelect = useCallback((event) => {
        if (!allowZooming) {
            return;
        }
        const { pageX: positionX, pageY: positionY, } = event;
        let allowedRegion;
        if (innerContainerRef === null || innerContainerRef === void 0 ? void 0 : innerContainerRef.current) {
            containerOffsetRef.current = getElementPosition(innerContainerRef === null || innerContainerRef === void 0 ? void 0 : innerContainerRef.current);
            allowedRegion = getAllowedRegion(innerContainerRef === null || innerContainerRef === void 0 ? void 0 : innerContainerRef.current);
        }
        const { left: offsetX = 0, top: offsetY = 0, } = containerOffsetRef.current || {};
        const updatedPositionX = positionX - offsetX - window.scrollX;
        const updatedPositionY = positionY - offsetY - window.scrollY;
        const updatedStartPoint = [updatedPositionX, updatedPositionY];
        if (allowedRegion && !insideArea(updatedStartPoint, allowedRegion)) {
            return;
        }
        /**
         * Fix Y position and height to fit container's block
         */
        startPointRef.current = updatedStartPoint;
        setPosition({
            width: 0,
            height: 0,
            left: updatedPositionX,
            top: updatedPositionY,
        });
        setIsVisible(true);
    }, [
        allowZooming,
        getAllowedRegion,
        innerContainerRef,
        containerOffsetRef,
    ]);
    const handleMoveSelect = useCallback((event) => {
        if (!isVisible) {
            return;
        }
        const { pageX: positionX, pageY: positionY, } = event;
        const { left: offsetX = 0, top: offsetY = 0, } = containerOffsetRef.current || {};
        const updatedPositionX = positionX - offsetX - window.scrollX;
        const updatedPositionY = positionY - offsetY - window.scrollY;
        let allowedRegion;
        if (innerContainerRef === null || innerContainerRef === void 0 ? void 0 : innerContainerRef.current) {
            allowedRegion = getAllowedRegion(innerContainerRef === null || innerContainerRef === void 0 ? void 0 : innerContainerRef.current);
        }
        animationFrameRef.current = requestAnimationFrame(() => resizeRegion(updatedPositionX, updatedPositionY, allowedRegion));
    }, [
        isVisible,
        innerContainerRef,
        resizeRegion,
        getAllowedRegion,
        containerOffsetRef,
    ]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleStopSelect = useCallback((event) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        event.stopPropagation();
        if (!isVisible) {
            return;
        }
        if (onSelect
            && position
            && (
            /**
             * If `minAllowedRegionToSelect` is set, then check that
             * selected region is bigger than minimum allowed limit
             */
            !minAllowedRegionToSelect
                || (position.width >= minAllowedRegionToSelect[0]
                    && position.height >= minAllowedRegionToSelect[1]))) {
            onSelect(calculatePositionWithRatio(position, ratio));
        }
        resetRegion();
    }, [
        minAllowedRegionToSelect,
        isVisible,
        onSelect,
        position,
        resetRegion,
        ratio,
    ]);
    const handleKeyDown = useCallback((event) => {
        switch (event.code) {
            case EKeyCode.Esc:
                resetRegion();
                break;
            default:
        }
    }, [resetRegion]);
    useEffect(() => {
        if (isVisible) {
            document.addEventListener('mouseup', handleStopSelect);
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('mouseup', handleStopSelect);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [
        isVisible,
        handleStopSelect,
        handleKeyDown,
    ]);
    return {
        containerHandlers: {
            onMouseDown: handleStartSelect,
            onMouseMove: handleMoveSelect,
            onMouseUp: handleStopSelect,
        },
        isVisible,
        position,
    };
}
