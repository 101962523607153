import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, memo, } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from '../../../../../components/Tooltip';
import { TOOLTIP_ID } from '../../consts';
import styles from './ManhattanPlotDataPointTooltip.css';
const ManhattanPlotDataPointTooltip = (props) => {
    const { tooltipContent, tooltipAPI, tooltipId = `${TOOLTIP_ID}:${uuidv4()}`, } = props;
    const offset = useMemo(() => [15, 0], []);
    // if tooltip hasn't content no need to try render empty tooltip
    if (!tooltipContent)
        return null;
    return (_jsx(Tooltip, { id: tooltipId, className: styles.tooltip, tooltipContent: tooltipContent, offset: offset, customTooltipAPI: tooltipAPI }, void 0));
};
/** Since memo loses generic props */
export default memo(ManhattanPlotDataPointTooltip);
