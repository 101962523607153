import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/button-has-type */
import { memo, } from 'react';
import classnames from 'classnames';
import { EButtonTypes } from './const';
import styles from './ButtonTogglerField.css';
function ButtonTogglerFieldBase(props) {
    const { value, options, onChange, getOptionValue, getOptionLabel, getOptionDisabled, disabled = false, className, optionsClassName, type = EButtonTypes.Submit, } = props;
    const containerClassName = classnames(styles.container, className);
    return (_jsx("div", Object.assign({ className: containerClassName }, { children: options.map((option) => {
            const optionClassName = classnames(styles.option, optionsClassName, {
                [styles.selected]: value && getOptionValue(option) === getOptionValue(value),
                [styles.disabled]: (getOptionDisabled && getOptionDisabled(option)) || disabled,
            });
            return (_jsx("button", Object.assign({ tabIndex: 0, className: optionClassName, onClick: () => onChange(option), disabled: (getOptionDisabled && getOptionDisabled(option)) || disabled, type: type }, { children: getOptionLabel(option) }), getOptionValue(option)));
        }) }), void 0));
}
export const ButtonTogglerField = memo(ButtonTogglerFieldBase);
